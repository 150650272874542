<template>
  <v-tooltip :disabled="disableTooltip" top color="#2C2D32" z-index="101">
    <button
      slot="activator"
      class="ControlsRepeatButton"
      :class="{
        'ControlsRepeatButton--large': size === 'large',
        'ControlsRepeatButton--medium': size === 'medium',
        'ControlsRepeatButton--small': size === 'small',
      }"
      @click="clickAction()"
    >
      <font-awesome-icon
        :icon="['far', 'arrows-repeat']"
        :class="getter_is_repeat_workflow || getter_is_repeat_step ? 'text-primary' : 'text-white'"
      />
      <div class="ControlsRepeatButton__repeatStep" v-show="getter_is_repeat_step">
        <span class="ControlsRepeatButton__number">1</span>
      </div>
    </button>
    <span> {{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
import { REPEAT_TYPES } from "@/constants/workflow-repeat-status.js";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {},
  props: {
    disableTooltip: Boolean,
    size: {
      type: String,
      default: "large",
    },
    isSingleStepPlayer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      repeatTypes: REPEAT_TYPES,
      repeatOrder: [REPEAT_TYPES.DISABLE, REPEAT_TYPES.WORKFLOW, REPEAT_TYPES.STEP],
    };
  },
  computed: {
    ...mapGetters("workflowPlayer", [
      "getter_current_repeat_type",
      "getter_is_disabled_repeat",
      "getter_is_repeat_workflow",
      "getter_is_repeat_step",
    ]),
    tooltipText() {
      switch (this.getter_current_repeat_type) {
        case this.repeatTypes.DISABLE:
          return this.$t("player.enableWorkflowRepeat");
        case this.repeatTypes.WORKFLOW:
          return this.$t("player.enableStepRepeat");
        case this.repeatTypes.STEP:
          return this.$t("player.disableRepeat");
        default:
          console.warn("Not support this repeat type: ", this.getter_current_repeat_type);
          return;
      }
    },
    repeatOrderIndex() {
      const currentIndex = this.repeatOrder.indexOf(this.getter_current_repeat_type);

      /** Only the single step player allows switching 'repeat Step'. */
      const stepTypeIndex = this.repeatOrder.indexOf(REPEAT_TYPES.STEP);
      if (this.isSingleStepPlayer) return stepTypeIndex - currentIndex;

      return currentIndex + 1 < this.repeatOrder.length ? currentIndex + 1 : 0;
    },
  },
  methods: {
    ...mapActions("workflowPlayer", ["changeRepeatType"]),
    clickAction() {
      this.changeRepeatType({ type: this.repeatOrder[this.repeatOrderIndex] });
    },
  },
};
</script>
<style lang="scss" scoped>
.ControlsRepeatButton {
  &--large {
    --icon-size: 30px;
  }
  &--medium {
    --icon-size: 20px;
  }
  &--small {
    --icon-size: 18px;
  }
  position: relative;
  svg {
    width: var(--icon-size);
    height: var(--icon-size);
  }
  &__repeatStep {
    position: absolute;
    display: flex;
    right: -5px;
    bottom: 3px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    padding-left: 3px;
    background: #4689f3;
  }
  &__number {
    display: inline-block;
    font-size: 12px;
    color: #ffffff;
    transform: scale(0.67); // scale font to 8px;
    transform-origin: 0 0;
  }
}
</style>
